<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"> Depósito via Boleto</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-if="isNullOrEmpty(boleto.barcode)">
        <div class="card">
          <div class="card-body" v-if="!docsOk">
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-md">Antes de fazer um depósito via Boleto, é necessário verificar a conta, completando o seu cadastro.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-12 text-center">
                <router-link to="/pages/user" class="btn btn-primary">
                  <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="row">
              <div class="col-md-6">
                <base-input label="Valor"
                            placeholder="0,00"
                            type="text"
                            name="Valor"
                            :rules="{max:14}"
                            v-money="money"
                            v-model="value">
                  <span slot="prepend">R$</span>
                </base-input>
                <button type="button" class="btn btn-primary mt-2" @click="generateNew"
                        v-bind:disabled="registeringBankSlip">
                  <i class="fa fa-edit"></i>
                  Gerar Boleto
                </button>
              </div>
              <div class="col-md-6">
                <card
                  header-classes="bg-transparent"
                  body-classes="px-lg-7"
                  class="card-pricing border-0 text-center mb-4">

                  <!--Header-->
                  <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Emita seus boletos com a
                    Olimpo Pay!</h4>

                  <!--Body-->
                  <div class="display-2">R$ {{ serviceFee }}</div>
                  <span class=" text-muted">por boleto</span>
                  <ul class="list-unstyled my-4">
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Valores de <b>R$10,00</b> até <b>R$9.999,99</b> por boleto.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">Apenas boletos compensados terão a tarifa cobrada.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span class="pl-2 text-sm">O valor da tarifa será adicionado ao valor do boleto.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex text-left">
                        <div>
                          <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                            <i class="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div>
                          <span
                            class="pl-2 text-sm">Após o pagamento, o boleto será compensado em até <b>2 dias úteis</b>.</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isNullOrEmpty(boleto.barcode)">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-between align-items-center">
              <div class="col">
                <div class="row">
                  <div class="col-md-6 text-center">
                    <button @click="printPdf(null)" class="btn btn-primary">
                      <i class="fas fa-print"/> Exibir PDF / Imprimir
                    </button>
                  </div>
                  <div class="col-md-6 text-center">
                    <button @click="()=>{boleto.barcode=''; value='0,00'}" class="btn btn-success">
                      <i class="fas fa-file-download"/> Novo Boleto
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- RECEIVER -->
            <hr>
            <div class="row">
              <div class="col-md-12">
                <p style="text-align: center; font-size: 16px">
                  <b>VALOR:</b> R$ {{ toMoney(boleto.value) }} <small class="text-muted" v-if="boleto.feeValue != null">(
                  + <b>R$</b> {{ toMoney(boleto.feeValue) }})</small> | <b>VENCIMENTO:</b> {{ boleto.dueDate }}
                </p>
                <p style="text-align: center; margin: 0; font-size: 18px">
                  {{ boleto.digitableLine }}
                </p>
                <span class="text-center">
                    <barcode format="ITF" display-value="false"
                             v-bind:value="boleto.barcode"></barcode>
                   </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tableData!=null && tableData.length > 0">
        <div class="card">
          <div class="border-0 card-header">
            <h3 class="mb-0">Histórico</h3>
          </div>
          <el-table :data="tableData"
                    row-key="id"
                    header-row-class-name="thead-light"
                    empty-text="Nenhuma transação encontrada.">
            <el-table-column label="Valor" min-width="135px" prop="value" sortable>
              <template slot-scope="props">
                    <span>
                      <span>R$ {{toMoney(props.row.value) }} </span>
                      <span v-if="props.row.feeValue != null">
                        <small class="text-muted">( + <b>R$</b> {{toMoney(props.row.feeValue)}})</small>
                      </span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Vencimento" min-width="150px" prop="dueDate" sortable>
              <template slot-scope="props">
                    <span>
                      <span>{{ props.row.dueDate }}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Status" min-width="130" prop="status" sortable>
              <template slot-scope="props">
                    <span>
                      <i class="fas fa-circle" :style="'color: #'+statusToColor(props.row.status)"></i>
                      <span> {{ statusToLabel(props.row.status) }}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column label="Criado" min-width="156px" prop="value" sortable>
              <template slot-scope="props">
                    <span>
                      <span>{{ props.row.created }}</span>
                    </span>
              </template>
            </el-table-column>
            <el-table-column class-name="td-actions" min-width="160px">
              <template slot-scope="props" v-if="props.row.status=='WAITING' || props.row.status=='CREATED'">
                <base-button
                  @click.native="showBarcode(props.row)"
                  class="like btn-link"
                  type="info"
                  size="sm"
                  title="Visualizar Código de Barras"
                  icon>
                  <i class="fas fa-barcode"></i>
                </base-button>
                <base-button
                  @click.native="printPdf(props.row.id)"
                  class="edit"
                  type="warning"
                  size="sm"
                  title="Exibir PDF / Imprimir"
                  icon>
                  <i class="fas fa-file-pdf"></i>
                  <i class="fas fa-print"></i>
                </base-button>

              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="card-footer d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category small">
                Exibindo de {{ pagination.fromNumber}} a {{ pagination.toNumber }} - Total {{ pagination.totalNumber }}
                registros
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} linhas selecionadas
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.totalNumber"
              v-on:input="loadData">
            </base-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import {BasePagination} from '@/components'
  import clientPaginationMixin from '../../util/tables/PaginatedTables/clientPaginationMixin'
  import {failWs, getWs, postWs} from '@/ws.service'
  import {isNullOrEmpty, moneyToFloat, toMoney} from "@/util/core.utils"
  import {VMoney} from 'v-money'

  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        docsOk: false,
        serviceFee: '3,00',
        minValue: '10,00',
        value: '0,00',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        registeringBankSlip: false,
        boleto: {
          id: '',
          value: '',
          feeValue: '',
          preChargedFee: true,
          digitableLine: '',
          barcode: '',
          dueDate: '',
          errorMessage: '',
          base64: null
        },
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        selectedRows: []
      };
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }

      this.loadData()
      this.loadFee()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      generateNew() {
        if (moneyToFloat(this.value) < moneyToFloat(this.minValue)) {
          this.$swal({
            title: 'Aviso!',
            text: 'O valor mínimo para depósito via boleto é R$ ' + this.minValue + '.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }
        this.registeringBankSlip = true

        this.$swal({
          title: 'Confirma o valor ?',
          html: "VALOR: <b>R$ " + this.value + "</b> <br/>",
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.callGenBoletoWs()
          } else {
            this.registeringBankSlip = false
          }
        })
      },
      callGenBoletoWs() {
        postWs("/boleto/generate-boleto",
          true, null,
          {
            value: moneyToFloat(this.value),
            productType: 'ACCOUNT_CREDIT'
          }, this.successGenerateNew,
          this.failGenerateNew)
      },
      successGenerateNew(response) {
        this.registeringBankSlip = false;
        this.pagination.currentPage = 1;
        this.value = '20,00';
        this.showBarcode(response.data);
        this.loadData();
      },
      failGenerateNew(error) {
        this.registeringBankSlip = false;
        let text = 'Falha ao gerar boleto! Tente mais tarde.'
        if (error.response.data.errorMessage == 'OPERATION_EXCEEDS_LIMIT') {
          text = 'Você atingiu o limite de emissão de boletos. Se deseja aumentar este limite, favor entrar em contato via chat.'
        } else if (error.response.data.errorMessage == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT') {
          text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação no menu \'VERIFICAR CONTA\'.'
        } else if (error.response.data.errorMessage == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
          text = 'Conta em processo de verificação de documentação, aguarde até que os dados e documentos enviados sejam verificados.'
        } else if (error.response.data.errorMessage == 'TOO_MANY_OPEN_BOLETOS_NO_FUNDS') {
          text = 'Limite de boletos em aberto atingido. Pague ou aguarde o vencimendo de ao menos um boleto em aberto para emitir novos boletos.'
        } else if (error.response.data.errorMessage == 'BLOCKED') {
          text = 'Devido a problemas em nossa equipe relacionados à contenção do COVID-19, suspendemos a emissão de boleto, com intuito de evitar transtornos quanto à demora na liberação do crédito e/ou possível suporte nos nossos serviços.'
        } else if (error.response.data.errorMessage == 'GREATER_THAN_THE_MAX_VALUE') {
          text = 'O valor máximo para depósito via boleto é R$ ' + toMoney(error.response.data.value) + '.'
        }
        this.$swal({
          title: 'Aviso!',
          text: text,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-warning btn-fill'
          },
          icon: 'warning'
        })
      },
      printPdf(id) {
        if (isNullOrEmpty(id)) {
          id = this.boleto.id
        }
        postWs("/boleto/pdf",
          true,
          {id: id}, null,
          this.successPrintPdf,
          failWs)
      },
      successPrintPdf(response) {
        this.$router.push({
          name: 'ShowPdf',
          params: {pageToBack: '/pages/deposit', pdfBase64: response.data.base64}
        })
      },
      showBarcode(boleto) {
        this.boleto.id = boleto.id
        this.boleto.value = boleto.value
        this.boleto.feeValue = boleto.feeValue
        this.boleto.digitableLine = boleto.digitableLine
        this.boleto.barcode = boleto.barcode
        this.boleto.dueDate = boleto.dueDate
      },
      loadData() {
        postWs("/boleto/last-requested-boletos",
          true, null,
          {
            pageNumber: this.pagination.currentPage - 1,
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      statusToLabel(status) {
        if ('PAID' == status) {
          return 'Pago'
        } else if ('PAYED_NOT_COMPENSATED' == status) {
          return 'Pago (Ag. Compensação)'
        } else if ('WAITING' == status) {
          return 'Pendente'
        } else if ('CREATED' == status) {
          return 'Registrando'
        } else {
          return 'Vencido/Cancelado'
        }
      },
      statusToColor(status) {
        if ('PAID' == status) {
          return '7ad06b'
        } else if ('PAYED_NOT_COMPENSATED' == status) {
          return '8096ff'
        } else if ('WAITING' == status) {
          return 'fbc658'
        } else if ('CREATED' == status) {
          return '6bccba'
        } else {
          return 'ef8157'
        }
      },
      loadFee() {
        getWs("/fee/list-user-fees",
          true, null, this.successLoadDataFee,
          failWs)
      },
      successLoadDataFee(response) {
        for (let fee of response.data.list) {
          if (fee.type === 'BOLETO') {
            var valueFee = toMoney(fee.value);
            this.serviceFee = valueFee;
          }
        }
      }
    },
    directives: {money: VMoney}
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
